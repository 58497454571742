<template>
  <div class="ant-glass-background radius-0">
    <dynamic-data-table
      table-title=""
      :table-records="filteredMeasurements"
      :table-headers="tableHeaders"
      :is-loading="wkbLibraryTableStatus === 'loading'"
      :can-edit="true"
      :can-delete="true"
      @editItem="setupEdit"
      @deleteItem="setupDelete"
    >
      <template #table-buttons>
        <v-text-field
          v-model="measurementSearch"
          flat
          filled
          dense
          clearable
          hide-details
          style="max-width: 300px !important"
          prepend-inner-icon="mdi-magnify"
          placeholder="Type here to search"
        />
      </template>
      <template #table-actions>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon
              class="ant-icon"
              v-on="on"
              @click="wkbMeasurementDialogDisplayed = true"
            >
              mdi-plus
            </v-icon>
          </template>
          <span>Add document</span>
        </v-tooltip>
      </template>
      <template #item.party="{ value, rowId, item }">
        <v-chip v-if="item.party" small class="mx-2" color="white"
          >{{ value }}
        </v-chip>
        <td v-else>-</td>
      </template>
    </dynamic-data-table>
    <v-dialog
      key="wkb-measurement"
      v-model="wkbMeasurementDialogDisplayed"
      max-width="600"
      @click:outside="closeWKBMeasurementDialog"
      @keydown.esc="closeWKBMeasurementDialog"
    >
      <v-card>
        <v-card-title class="justify-center text-uppercase headline">
          {{ wkbMeasurementItem.id ? 'Update' : 'Add' }} Measurement
        </v-card-title>
        <v-divider />
        <div class="px-10 pb-5">
          <v-form ref="create-wkb-measurement-form" @submit.prevent>
            <div class="d-flex">
              <ant-input label="Number" class="mr-5">
                <template #input-field>
                  <v-text-field
                    v-model="wkbMeasurementItem.number"
                    filled
                    :disabled="wkbLibraryTableStatus === 'loading'"
                    placeholder="Number"
                    :rules="[rules.required]"
                    hide-details
                    dense
                    type="number"
                  />
                </template>
              </ant-input>

              <ant-input label="Type">
                <template #input-field>
                  <v-select
                    v-model="wkbMeasurementItem.type"
                    :items="['leanform', 'document', 'task']"
                    filled
                    placeholder="Type"
                    :rules="[rules.required]"
                    :disabled="wkbLibraryTableStatus === 'loading'"
                    hide-details
                    dense
                  />
                </template>
              </ant-input>
            </div>

            <ant-input label="Name">
              <template #input-field>
                <v-text-field
                  v-model="wkbMeasurementItem.name"
                  filled
                  :disabled="wkbLibraryTableStatus === 'loading'"
                  placeholder="Name"
                  :rules="[rules.required]"
                  hide-details
                  dense
                />
              </template>
            </ant-input>

            <ant-input label="Description" is-optional>
              <template #input-field>
                <v-textarea
                  v-model="wkbMeasurementItem.description"
                  filled
                  hide-details
                  :disabled="wkbLibraryTableStatus === 'loading'"
                  placeholder="Please add a description here"
                />
              </template>
            </ant-input>

            <div class="d-flex">
              <ant-input label="Sample level" class="mr-5">
                <template #input-field>
                  <v-select
                    v-model="wkbMeasurementItem.sample_level"
                    :items="[
                      'Project',
                      'Woningen',
                      'Bouwblokken',
                      'Types',
                      'Bouwlagen',
                    ]"
                    :disabled="wkbLibraryTableStatus === 'loading'"
                    filled
                    placeholder="Sample Level"
                    :rules="[rules.required]"
                    hide-details
                    dense
                  />
                </template>
              </ant-input>

              <ant-input label="Sample level">
                <template #input-field>
                  <v-select
                    v-model="wkbMeasurementItem.party"
                    :items="['Kwaliteitsborger', 'DHME']"
                    dense
                    hide-details
                    :rules="[rules.required]"
                    :disabled="wkbLibraryTableStatus === 'loading'"
                    filled
                    placeholder="party"
                  />
                </template>
              </ant-input>
            </div>
          </v-form>
        </div>
        <v-card-actions class="ant-border-top ant-dialog-actions-bg">
          <v-spacer />
          <v-btn
            color="primary"
            text
            small
            :disabled="wkbLibraryTableStatus === 'loading'"
            @click="closeWKBMeasurementDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            small
            elevation="0"
            :loading="wkbLibraryTableStatus === 'loading'"
            :disabled="wkbLibraryTableStatus === 'loading'"
            @click="saveOrUpdateWKBMeasurement"
            >{{ wkbMeasurementItem.id ? 'update' : 'create' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <delete-dialog
      :dialog="wkbMeasurementDeleteDialogDisplayed"
      title="Are you sure you want to delete this record?"
      @closeDialog="closeWKBMeasurementDialog"
      @deleteAction="deleteWkbMeasurementRecord"
    />
  </div>
</template>

<script>
import DeleteDialog from '@/components/DeleteDialog.vue';
import DynamicDataTable from '@/components/DynamicDataTable.vue';
import { mapGetters } from 'vuex';
import WKBMixin from '@/modules/daiwa-house-modular-europe/WkbLibrary/DhmeWkbLibraryMixin';
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'DhmeWkbMeasurementsLibrary',
  components: { AntInput, DeleteDialog, DynamicDataTable },
  mixins: [WKBMixin],
  data: () => {
    return {
      tableRecords: [],
      tableHeaders: [
        {
          text: 'number',
          value: 'number',
        },
        {
          text: 'type',
          value: 'type',
        },
        {
          text: 'name',
          value: 'name',
        },
        {
          text: 'description',
          value: 'description',
        },
        {
          text: 'sample lvl',
          value: 'sample_level',
        },
        {
          text: 'default sample size',
          value: 'default_sample_size',
        },
        {
          text: 'party',
          value: 'party',
          hasSlot: true,
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
        },
      ],
      wkbMeasurementItem: {
        type: 'leanform',
      },
      wkbMeasurementDialogDisplayed: false,
      wkbMeasurementDeleteDialogDisplayed: false,
      rules: {
        required: (value) => !!value || 'Required.',
      },
      measurementSearch: '',
    };
  },
  computed: {
    ...mapGetters([
      'wkbLibraryMeasurements',
      'wkbLibraryTableStatus',
      'wkbLibraryMeasurementsForms',
    ]),

    filteredMeasurements() {
      let measurements = [...this.wkbLibraryMeasurements];

      if (this.measurementSearch) {
        measurements = measurements.filter((item) =>
          item.name.toLowerCase().includes(this.measurementSearch.toLowerCase())
        );
      }

      return measurements.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    closeWKBMeasurementDialog() {
      this.wkbMeasurementItem = Object.assign(
        {},
        {
          type: 'leanform',
        }
      );
      this.wkbMeasurementDialogDisplayed = false;
      this.wkbMeasurementDeleteDialogDisplayed = false;
      this.$refs['create-wkb-measurement-form'].reset();
    },
    setupEdit(record) {
      this.wkbMeasurementItem = Object.assign({}, record);
      this.wkbMeasurementDialogDisplayed = true;
    },
    setupDelete(record) {
      this.wkbMeasurementItem = Object.assign({}, record);
      this.wkbMeasurementDeleteDialogDisplayed = true;
    },
    saveOrUpdateWKBMeasurement() {
      if (this.$refs['create-wkb-measurement-form'].validate()) {
        if (this.wkbMeasurementItem.id) {
          // update item
          const recordId = this.wkbMeasurementItem.id;
          delete this.wkbMeasurementItem.id;
          this.$store
            .dispatch('updateWkbLibraryRecord', {
              table: 'CFFA_DHME_WKB_MEASUREMENTS',
              recordId,
              body: {
                record: this.wkbMeasurementItem,
              },
            })
            .then(() => {
              this.closeWKBMeasurementDialog();
            });
        } else {
          // create item
          this.$store
            .dispatch('createWkbLibraryRecord', {
              table: 'CFFA_DHME_WKB_MEASUREMENTS',
              body: {
                record: this.wkbMeasurementItem,
              },
            })
            .then(() => {
              this.closeWKBMeasurementDialog();
            });
        }
      }
    },
    deleteWkbMeasurementRecord() {
      this.$store
        .dispatch('deleteWkbLibraryRecord', {
          table: 'CFFA_DHME_WKB_MEASUREMENTS',
          recordId: this.wkbMeasurementItem.id,
        })
        .then(() => {
          this.closeWKBMeasurementDialog();
        });
    },
  },
};
</script>

<style scoped></style>
