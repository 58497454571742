<template>
  <div class="d-flex fill-height">
    <div
      class="flex-1 ant-glass-background radius-0 ant-border-right pa-5 d-flex flex-column"
    >
      <div class="d-flex">
        <v-spacer />
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon class="ant-icon" v-on="on" @click="chapterDialog = true">
              mdi-plus
            </v-icon>
          </template>
          <span>Add chapter</span>
        </v-tooltip>
        <dhme-wkb-chapter-dialog
          :displayed="chapterDialog"
          :head-chapter="headChapter"
          :update-chapter="updateChapterItem"
          @closeDialog="
            chapterDialog = false;
            headChapter = null;
            updateChapterItem = null;
          "
        />
        <delete-dialog
          :dialog="deleteChapterDialog"
          :title="`Are you sure you
        want to delete chapter ${deleteChapterItem.title}`"
          @closeDialog="
            deleteChapterDialog = false;
            deleteChapterItem = {};
          "
          @deleteAction="deleteChapter"
        />
        <dhme-wkb-risks-dialog
          :displayed="riskDialog"
          :update-risk="updateRiskItem"
          :chapter="riskChapter"
          @closeDialog="
            riskDialog = false;
            updateRiskItem = null;
          "
        />

        <delete-dialog
          :title="`Are you sure you want to remove ${deleteRiskItem.name}`"
          :dialog="deleteRiskDialog"
          @closeDialog="
            deleteRiskDialog = false;
            deleteRiskItem = {};
          "
          @deleteAction="deleteRisk"
        />
      </div>
      <div class="overflow-y-auto flex-1 flex-scroll-height">
        <div
          v-for="headChapterItem in headChapters"
          :key="headChapterItem.id"
          class="px-1 py-1"
        >
          <v-subheader style="height: auto; font-size: 16px">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <span
                  class="c-pointer"
                  v-on="on"
                  @click="
                    updateChapterItem = headChapterItem;
                    chapterDialog = true;
                  "
                >
                  Chapter {{ headChapterItem.chapter }}.
                  {{ headChapterItem.title }}
                </span>
              </template>
              <span>Edit {{ headChapterItem.title }}</span>
            </v-tooltip>
            <v-spacer />
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon
                  class="ant-icon"
                  small
                  v-on="on"
                  @click="
                    chapterDialog = true;
                    headChapter = headChapterItem;
                  "
                >
                  mdi-plus
                </v-icon>
              </template>
              <span>Add subchapter</span>
            </v-tooltip>
            <v-divider vertical class="mx-2" />
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon
                  class="ant-icon"
                  small
                  v-on="on"
                  @click="
                    deleteChapterDialog = true;
                    deleteChapterItem = headChapterItem;
                  "
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>Delete Chapter</span>
            </v-tooltip>
          </v-subheader>
          <div class="pl-3 py-2">
            <div
              v-for="subChapterItem in getSubChapters(headChapterItem)"
              :key="subChapterItem.id"
            >
              <v-subheader style="height: auto">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <span
                      class="c-pointer"
                      v-on="on"
                      @click="
                        headChapter = headChapterItem;
                        updateChapterItem = subChapterItem;
                        chapterDialog = true;
                      "
                    >
                      {{ subChapterItem.chapter }}.{{
                        subChapterItem.sub_chapter
                      }}:
                      {{ subChapterItem.title }}
                    </span>
                  </template>
                  <span>Edit {{ subChapterItem.title }}</span>
                </v-tooltip>
                <v-spacer />
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon
                      class="ant-icon"
                      small
                      v-on="on"
                      @click="
                        riskDialog = true;
                        riskChapter = subChapterItem;
                      "
                    >
                      mdi-plus
                    </v-icon>
                  </template>
                  <span>Add risk</span>
                </v-tooltip>
                <v-divider vertical class="mx-2" />
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon
                      class="ant-icon"
                      small
                      v-on="on"
                      @click="
                        deleteChapterDialog = true;
                        deleteChapterItem = subChapterItem;
                      "
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete Chapter</span>
                </v-tooltip>
              </v-subheader>
              <div class="py-1 pl-3">
                <div
                  v-for="risk in getRisksByChapter(subChapterItem)"
                  :key="risk.id"
                  style="font-size: 12px"
                  class="d-flex align-center py-1 pl-4 pr-4 library-risk-item"
                  :class="{
                    'library-risk-item-active': risk === selectedRisk,
                  }"
                  @click="selectedRisk = risk"
                >
                  <div class="mr-2" style="min-width: 40px">
                    {{ risk.chapter }}.{{ risk.sub_chapter }}.{{ risk.number }}
                  </div>
                  <div>
                    {{ risk.name }}
                  </div>
                  <v-spacer />
                  <div class="d-flex justify-end">
                    <v-tooltip left>
                      <template #activator="{ on, attrs }">
                        <v-icon small class="mr-2" v-on="on"
                          >mdi-format-list-numbered
                        </v-icon>
                        <div>
                          {{ countRiskMeasures(risk) }}
                        </div>
                      </template>
                      <span>Active measurements</span>
                    </v-tooltip>

                    <v-divider class="mx-2" vertical />
                    <div class="font-italic" style="min-width: 50px">
                      {{ risk.NLSFB ? risk.NLSFB : '-' }}
                    </div>
                  </div>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon
                        class="ant-icon"
                        small
                        v-on="on"
                        @click.stop="
                          riskDialog = true;
                          updateRiskItem = risk;
                        "
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Edit risk</span>
                  </v-tooltip>
                  <v-divider vertical class="mx-2" />
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon
                        class="ant-icon"
                        small
                        v-on="on"
                        @click="
                          deleteRiskDialog = true;
                          deleteRiskItem = risk;
                        "
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Delete risk</span>
                  </v-tooltip>
                </div>
              </div>
            </div>
          </div>
          <v-divider />
        </div>
      </div>
    </div>
    <div v-if="selectedRisk" class="flex-1 pa-5 d-flex flex-column">
      <v-text-field
        v-model="measurementSearch"
        flat
        filled
        dense
        clearable
        hide-details
        class="flex-0-important mb-5"
        prepend-inner-icon="mdi-magnify"
        placeholder="Type here to search"
      />
      <v-subheader style="height: auto" class="pa-0">Measurements</v-subheader>
      <div class="overflow-y-auto flex-1 flex-scroll-height">
        <div
          v-for="measurement in filteredMeasurements"
          :key="measurement.id"
          class="d-flex align-center py-1 my-1"
          style="font-size: 12px"
        >
          <v-simple-checkbox
            dense
            hide-details
            :ripple="false"
            :value="isMeasurementMappedToRisk(measurement)"
            class="shrink mt-0 pt-0"
            @click="toggleMapMeasurementToRisk(measurement)"
          />
          <div class="font-italic" style="min-width: 60px">
            {{ measurement.type }}
          </div>
          <v-divider class="mx-2" vertical />
          {{ measurement.name }}
          <v-tooltip v-if="isMeasurementMappedToRisk(measurement)" right>
            <template #activator="{ on, attrs }">
              <v-icon
                small
                class="ml-2"
                v-on="on"
                @click="toggleAutoActive(measurement)"
                >{{
                  getMeasurementRiskRelation(measurement).auto_active
                    ? 'mdi-checkbox-marked-circle'
                    : 'mdi-checkbox-blank-circle-outline'
                }}
              </v-icon>
            </template>
            <span>Toggle auto active</span>
          </v-tooltip>
          <v-spacer />
          <v-chip
            v-for="phase in getMeasurementPhases(measurement)"
            :key="phase"
            small
            class="mx-2"
            color="white"
            >{{ phase }}
          </v-chip>
          <v-tooltip left>
            <template #activator="{ on, attrs }">
              <v-chip color="primary" small v-on="on"
                >{{ measurement.party ? measurement.party : '-' }}
              </v-chip>
            </template>
            <span>Executing party</span>
          </v-tooltip>
          <v-divider vertical class="mx-2" />
          <div style="min-width: 50px">
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-1"
                  :disabled="!isMeasurementMappedToRisk(measurement)"
                  v-on="on"
                  >mdi-percent-circle
                </v-icon>
              </template>
              <span>Default sample size</span>
            </v-tooltip>
            {{
              measurement.default_sample_size
                ? measurement.default_sample_size
                : '-'
            }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="font-italic flex-1 d-flex align-center justify-center">
      Please select a risk first
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DeleteDialog from '@/components/DeleteDialog.vue';
import DhmeWkbLibraryMixin from '@/modules/daiwa-house-modular-europe/WkbLibrary/DhmeWkbLibraryMixin';
import DhmeWkbRisksDialog from '@/modules/daiwa-house-modular-europe/WkbLibrary/DhmeWkbRisksDialog.vue';
import DhmeWkbChapterDialog from '@/modules/daiwa-house-modular-europe/WkbLibrary/DhmeWkbChapterDialog.vue';

export default {
  name: 'DhmeWkbRisksLibrary',
  components: { DhmeWkbChapterDialog, DhmeWkbRisksDialog, DeleteDialog },
  mixins: [DhmeWkbLibraryMixin],
  data: () => {
    return {
      selectedRisk: null,
      chapterDialog: false,
      headChapter: null,
      updateChapterItem: null,
      deleteChapterDialog: false,
      deleteChapterItem: {},

      riskChapter: {},
      riskDialog: false,
      updateRiskItem: null,
      deleteRiskDialog: false,
      deleteRiskItem: {},
      measurementSearch: '',
    };
  },
  computed: {
    ...mapGetters([
      'wkbLibraryRisks',
      'wkbLibraryMeasurements',
      'wkbLibraryMeasurementsForms',
      'wkbLibraryMapping',
      'wkbLibraryChapters',
    ]),
    headChapters() {
      return this.wkbLibraryChapters
        .filter((item) => !item.sub_chapter)
        .sort((a, b) => a.chapter - b.chapter);
    },
    filteredMeasurements() {
      let measurements = [...this.wkbLibraryMeasurements];

      if (this.measurementSearch) {
        measurements = measurements.filter((item) =>
          item.name.toLowerCase().includes(this.measurementSearch.toLowerCase())
        );
      }

      return measurements.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    deleteChapter() {
      this.$store
        .dispatch('deleteWkbLibraryRecord', {
          table: 'CFFA_DHME_WKB_CHAPTERS',
          recordId: this.deleteChapterItem.id,
        })
        .then(() => {
          this.deleteChapterDialog = false;
          this.deleteChapterItem = {};
        });
    },
    deleteRisk() {
      this.$store
        .dispatch('deleteWkbLibraryRecord', {
          table: 'CFFA_DHME_WKB_RISKS',
          recordId: this.deleteRiskItem.id,
        })
        .then(() => {
          this.deleteRiskDialog = false;
          this.deleteRiskItem = {};
        });
    },
    getSubChapters(headChapter) {
      return this.wkbLibraryChapters
        .filter(
          (item) => item.chapter === headChapter.chapter && item.sub_chapter
        )
        .sort((a, b) => a.sub_chapter - b.sub_chapter);
    },
    countRiskMeasures(risk) {
      return this.wkbLibraryMapping.filter((item) => item.risk_id === risk.id)
        .length;
    },
    getRisksByChapter(chapter) {
      return this.wkbLibraryRisks
        .filter(
          (risk) =>
            risk.chapter === chapter.chapter &&
            risk.sub_chapter === chapter.sub_chapter
        )
        .sort((a, b) => a.number - b.number);
    },
    isMeasurementMappedToRisk(measurement) {
      return (
        this.wkbLibraryMapping.findIndex(
          (relation) =>
            relation.risk_id === this.selectedRisk.id &&
            relation.measurement_id === measurement.id
        ) !== -1
      );
    },
    getMeasurementRiskRelation(measurement) {
      return this.wkbLibraryMapping.find(
        (relation) =>
          relation.risk_id === this.selectedRisk.id &&
          relation.measurement_id === measurement.id
      );
    },
    toggleAutoActive(measurement) {
      let riskMeasurement = this.getMeasurementRiskRelation(measurement);
      let body = {
        record: {
          auto_active: !riskMeasurement.auto_active,
        },
      };

      this.$store.dispatch('updateRiskMeasurementRelation', {
        recordId: riskMeasurement.id,
        body,
      });
    },
    toggleMapMeasurementToRisk(measurement) {
      let bool = !this.isMeasurementMappedToRisk(measurement);
      if (bool) {
        // add measurement - risk mapping
        let body = {
          record: {
            risk_id: this.selectedRisk.id,
            measurement_id: measurement.id,
          },
        };
        this.$store.dispatch('addRiskMeasurementRelation', body);
      } else {
        // remove measurement - risk mapping
        let riskMeasurement = this.getMeasurementRiskRelation(measurement);
        this.$store.dispatch(
          'deleteRiskMeasurementRelation',
          riskMeasurement.id
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.library-risk-item {
  cursor: pointer;
  transition: 100ms ease;

  &.library-risk-item-active {
    background: #f2f2f2;
  }

  &:hover {
    background: #f2f2f2;
  }
}
</style>
