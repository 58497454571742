<template>
  <v-dialog
    v-model="showDialog"
    max-width="600"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title class="justify-center text-uppercase headline">
        {{ chapterItem.id ? 'Update' : 'Add' }} Chapter
      </v-card-title>
      <v-divider />
      <div class="px-10 pb-5">
        <v-form ref="chapter-form" @submit.prevent>
          <div class="d-flex">
            <ant-input label="Chapter" class="mr-5 flex-1">
              <template #input-field>
                <v-text-field
                  v-model="chapterItem.chapter"
                  filled
                  placeholder="Chapter"
                  :rules="[rules.required]"
                  hide-details
                  :disabled="
                    wkbLibraryTableStatus === 'loading' || headChapter !== null
                  "
                  dense
                  type="number"
                  :min="0"
                />
              </template>
            </ant-input>

            <ant-input
              label="Chapter"
              class="flex-1"
              :is-optional="!headChapter"
            >
              <template #input-field>
                <v-text-field
                  v-model="chapterItem.sub_chapter"
                  filled
                  placeholder="Subchapter"
                  hide-details
                  dense
                  :rules="headChapter ? [rules.required] : []"
                  :disabled="
                    wkbLibraryTableStatus === 'loading' ||
                    chapterItem.chapter === null
                  "
                  type="number"
                  :min="0"
                />
              </template>
            </ant-input>
          </div>

          <ant-input label="Title">
            <template #input-field>
              <v-text-field
                v-model="chapterItem.title"
                filled
                placeholder="Title"
                :rules="[rules.required]"
                :disabled="wkbLibraryTableStatus === 'loading'"
                hide-details
                dense
              />
            </template>
          </ant-input>
        </v-form>
      </div>
      <v-card-actions class="ant-border-top ant-dialog-actions-bg">
        <v-spacer />
        <v-btn
          text
          small
          color="error"
          class="mx-2"
          :disabled="wkbLibraryTableStatus === 'loading'"
          @click="closeDialog"
          >cancel
        </v-btn>
        <v-btn
          color="primary"
          small
          :disabled="wkbLibraryTableStatus === 'loading'"
          :loading="wkbLibraryTableStatus === 'loading'"
          @click="saveOrUpdateChapter"
          >{{ chapterItem.id ? 'update' : 'create' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'DhmeWkbChapterDialog',
  components: { AntInput },
  props: {
    displayed: {
      type: Boolean,
      required: true,
    },
    headChapter: {
      type: Object,
      required: false,
      default: null,
    },
    updateChapter: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => {
    return {
      chapterItem: {},
      rules: {
        required: (value) => !!value || 'Required.',
      },
    };
  },
  computed: {
    ...mapGetters(['wkbLibraryTableStatus']),
    showDialog() {
      return this.displayed;
    },
  },
  watch: {
    headChapter(value) {
      if (value) {
        this.chapterItem.chapter = value.chapter;
      }
    },
    updateChapter(value) {
      if (value) {
        this.chapterItem = Object.assign({}, value);
      }
    },
  },
  methods: {
    closeDialog() {
      this.chapterItem = Object.assign({}, {});
      this.$refs['chapter-form'].reset();
      this.$emit('closeDialog');
    },
    saveOrUpdateChapter() {
      if (this.$refs['chapter-form'].validate()) {
        if (this.chapterItem.id) {
          // update item
          const recordId = this.chapterItem.id;
          delete this.chapterItem.id;
          this.$store
            .dispatch('updateWkbLibraryRecord', {
              table: 'CFFA_DHME_WKB_CHAPTERS',
              recordId,
              body: {
                record: this.chapterItem,
              },
            })
            .then(() => {
              this.closeDialog();
            });
        } else {
          // create item
          this.$store
            .dispatch('createWkbLibraryRecord', {
              table: 'CFFA_DHME_WKB_CHAPTERS',
              body: {
                record: this.chapterItem,
              },
            })
            .then(() => {
              this.closeDialog();
            });
        }
      }
    },
  },
};
</script>

<style scoped></style>
